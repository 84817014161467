// components/Layout.js
import React, { createContext, useState, useEffect } from 'react';
import Head from 'next/head';
import Header from './Header';
import Footer from './Footer';
import { Analytics } from "@vercel/analytics/react";

export const GeoLocationContext = createContext('Desconocido');

export default function Layout({ children, title, metaTitle, metaDescription, isPricingPage }) {
    const [location, setLocation] = useState(null);

    useEffect(() => {
        fetch('/api/geo')
            .then((res) => res.json())
            .then((data) => {
                console.log('Geo Data:', data);
                setLocation(data.country || 'Desconocido');
            })
            .catch((error) => {
                console.error('Geo Fetch Error:', error);
                setLocation('Desconocido');
            });
    }, []);

    console.log('Location in Context:', location);

    return (
        <GeoLocationContext.Provider value={location}>
            <div>
                <Analytics/>
                <Head>
                    <meta charSet="UTF-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>{title}</title>
                    <meta name="title" content={metaTitle} />
                    <meta name="description" content={metaDescription} />

                    {isPricingPage && (
                        <>
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Basic website",
                                    "description": "Your hassle-free ticket to a stunning WordPress website that captivates your audience and propels your small business forward!",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 650,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Businesses",
                                    "description": "Crafts tailored online solutions, empowering businesses to thrive in the digital realm with captivating web design and seamless functionality.",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 1100,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Online Store",
                                    "description": "Propels your business into the digital marketplace, a sophisticated platform to elegantly showcase and effortlessly sell products to a global audience.",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 1300,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Maintenance & Support",
                                    "description": "Your peace-of-mind partner, WpMonitor ensures your WordPress site stays secure, updated, and optimized for peak performance!",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 25,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Data Migration & Configuration",
                                    "description": "Your seamless solution to effortlessly transfer and optimize your data for enhanced performance and functionality.",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 20,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                __html: JSON.stringify({
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "Support & Customization",
                                    "description": "Your dedicated ally for personalized assistance and tailored enhancements to elevate your digital presence.",
                                    "offers": {
                                        "@type": "Offer",
                                        "price": 20,
                                        "priceCurrency": "USD"
                                    }
                                })
                                }}
                            />
                        </>
                    )}
                </Head>
                <Header />
                <main>
                    {children}
                </main>
                <div className="max-w-screen-xl mx-auto">
                    <section id='footer-block' className='text-center p-20 mt-10'>
                        <h5 className='font-extrabold mb-5'>¡PONGÁMONOS A TRABAJAR!</h5>
                        <h3>
                            Estamos ansiosos por escuchar los desafíos que enfrentan tu marca y tu negocio, sin importar si no estás seguro de cuál debería ser tu próximo paso.
                        </h3>
                    </section>
                    <Footer isPricingPage={true} />
                </div>
            </div>
        </GeoLocationContext.Provider>
    )
}
